import React, {useState, useEffect} from 'react';
import line from "../../images/musica-liturgica/home/line.png";
import Divider from '@mui/material-next/Divider';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { NavLink } from 'react-router-dom';
import Button from '@mui/material/Button';

// import ptBr from 'date-fns/locale/pt-br';
// with date-fns v3.x
import { pt } from 'date-fns/locale/pt';

import axios from 'axios';

// baseUrl
import {baseUrl} from "../../services/adalConfig.js";

import "./Musica.css";

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

export default function TempoLiturgico(props) {

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const [value, setValue] = React.useState(new Date().getFullYear() % 3);
    const [missasTempoLiturgico, setMissasTempoLiturgico] = useState({"tempo-comum": {}, pascoa: {}, quaresma: {}, advento: {}});

    useEffect(() => {
       function handleResize() {
         setWindowDimensions(getWindowDimensions());
       }
       window.addEventListener('resize', handleResize);
       return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        var getUrl = baseUrl + "/get-tempos-liturgicos";

        axios.get(getUrl)
            .then(resp => {
                setMissasTempoLiturgico(resp.data);
            });

    }, []);
 
    if (window.innerWidth <= 768) {
       return (<TempoLiturgicoHomeMobile tempoLiturgico={props.tempoLiturgico} value={value} setValue={setValue} missasTempoLiturgico={missasTempoLiturgico}/>);
    } else {
       return (<TempoLiturgicoHomeDesktop tempoLiturgico={props.tempoLiturgico} value={value} setValue={setValue} missasTempoLiturgico={missasTempoLiturgico}/>);
    }
}

const dictTemps = {
    "tempo-comum": "Tempo Comum",
    "advento": "Advento",
    "quaresma": "Quaresma",
    "pascoa": "Páscoa"
};

function TempoLiturgicoHomeDesktop(props) {

    var currentDict = props.missasTempoLiturgico[props.tempoLiturgico];

    return (
        <div style={{marginBottom: "100px", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", marginTop: "90px", overflow: "hidden"}}>
                <div class="musica-home-header" style={{marginTop: "20px", marginBottom: "45px"}}>
                    <Divider sx={{
                        "&::before, &::after": {
                        borderColor: "#bc1200",
                        },
                    }}>
                        {dictTemps[props.tempoLiturgico]}
                    </Divider>
                </div>
                <ul>
                {
                    Object.keys(currentDict).map(item => {
                        return <li class="tempos-item-list"><NavLink to={currentDict[item]} style={{color: "black", textDecoration: "none"}}> {item}</NavLink></li>    
})
                }
            </ul>
        </div>
    )
}


function TempoLiturgicoHomeMobile(props) {
 

    var currentDict = props.missasTempoLiturgico[props.tempoLiturgico];

    return (
        <div style={{marginBottom: "100px", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", marginTop: "90px", overflow: "hidden"}}>
                <div class="musica-home-header" style={{marginTop: "20px", marginBottom: "45px"}}>
                    <Divider sx={{
                        "&::before, &::after": {
                        borderColor: "#bc1200",
                        },
                    }}>
                        {dictTemps[props.tempoLiturgico]}
                    </Divider>
                </div>
                <div style={{width: "95%"}}>
                {
                    Object.keys(currentDict).map((item, index) => {
                        if (index == 0) {
                            return <NavLink to={currentDict[item]} style={{padding: "18px 0px", borderWidth: "1px 0px 1px 0px", borderColor: "#bc1200", borderStyle: "solid", width: "100%", display: "flex", justifyContent: "center", textDecoration: "none"}}><p style={{color: "black", textDecoration: "none", fontSize: "18px", fontWeight: "600"}}>{item}</p></NavLink>
                        } else {
                            return <NavLink to={currentDict[item]} style={{padding: "18px 0px", borderWidth: "0px 0px 1px 0px", borderColor: "#bc1200", borderStyle: "solid", width: "100%", display: "flex", justifyContent: "center", textDecoration: "none"}}><p style={{color: "black", textDecoration: "none", fontSize: "18px", fontWeight: "600"}}>{item}</p></NavLink>
                        }
})  
                }
                
            </div>
        </div>
    )
    
}