import {useState, useEffect} from 'react';

import TextField from '@mui/material/TextField';
import EditIcon from '@mui/icons-material/Edit';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from "react-router-dom";
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

// baseUrl
import {baseUrl} from "../../../services/adalConfig.js";

export default function CalendarioMissas(props) {

	const [valuesDict, setValuesDict] = useState({missa: null, data: dayjs(new Date().toISOString().substring(0,10))});
	const [missas, setMissas] = useState({'tempo-comum': {}, 'pascoa': {}, 'quaresma': {}, 'advento': {}, 'tempo-comum': {}, 'todos-os-tempos': {}});
	const [rows, setRows] = useState([]);
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();

	const changeValues = (field, newValue) => {
		console.log("oi");
		var tempDict = valuesDict;

		tempDict = {...tempDict, [field]: newValue};
		console.log(tempDict);
		setValuesDict(tempDict);
	}

	async function handleEditRow(rowDict) {

		setValuesDict({missa: {id: rowDict.id_missa, nome: rowDict.nome}, data: dayjs(rowDict.data.substring(0,10)), id: rowDict.id});
		props.setIsUpdateMode(true);
	}

	function cancelEditRow() {
		setValuesDict({missa: null, data: dayjs(new Date().toISOString().substring(0,10))});
		props.setIsUpdateMode(false);
		atualizaDados();
	}

	async function submitForm() {

		if (props.isUpdateMode) {
			var postUrl = baseUrl + "/update-data-missa";
		} else {
			var postUrl = baseUrl + "/cadastrar-data-missa";
		}
		
		var payload = valuesDict;

		var emptyValues = [];
		var payloadKeys = Object.keys(payload);
		console.log("keys");
		console.log(payloadKeys);
		for (let i = 0; i < payloadKeys.length; i++) {
			if (payload[payloadKeys[i]] == "" || payload[payloadKeys[i]] == null) {
				if (payloadKeys[i] == "tipo") 
					continue;
				else 
					emptyValues.push(payloadKeys[i]);
			}
		}

		if (emptyValues.length > 0) {
			props.setAlertDict({open: true, texto: `Os seguintes campos estão vazios: ${emptyValues.join(", ")}`, severidade: "error"});
			return;
		}

		payload["token"] = props.token;

		console.log("strange words");
		console.log({
			...payload,
			id_missa: payload["missa"]["id"],
			data: payload["data"].toDate().toISOString().substring(0,10)
		});

		setLoading(true);

		await axios.post(postUrl, {
			...payload,
			id_missa: payload["missa"]["id"],
			data: payload["data"].toDate().toISOString().substring(0,10)
		})
			.then(resp => {
				console.log("Bach is cool");
				console.log(resp.data);
				setLoading(false);
				props.setAlertDict({open: true, texto: "Sucesso ao inserir nova data de missa!", severidade: "success"});
            })
            .catch((exp) => {
            	setLoading(false);
				props.setAlertDict({open: true, texto: "Erro ao inserir nova data de missa.", severidade: "error"});
            });

        if (props.isUpdateMode) cancelEditRow();
	}

	const getOptions = () => {
		var tempDict = {};
		
		tempDict = {...missas['tempo-comum'], ...missas['pascoa'], ...missas['quaresma'], ...missas['advento'], ...missas['todos-os-tempos']};

		var tempList = [];
		for (const item in tempDict) {
		    tempList.push({"id": tempDict[item], "nome": item});
		}
		if (tempList == undefined || tempList == null) return [];
		else return tempList;
	}

	const columns = [
    	{
			field: 'nome',
		    headerName: 'Nome',
		    width: 140,
		    flex: 1
    	},
    	{
    		field: 'data',
		    headerName: 'Data',
		    width: 140,
		    flex: 1,
		    valueGetter: (value) => value.substring(8,10) + "/" + value.substring(5,7) + "/" + value.substring(0,4)
    	}, 
		{
	        field: 'actions',
	        type: 'actions',
	        width: 80,
	        getActions: (params) => {
	        	console.log(params.row.tipo);
	        	if (params.row.tipo == "Comum") {
		    		return [];
		    	} else {
		    		return ([
		        	          	<EditIcon
		        	          		onClick={() => handleEditRow(params.row)}
		        	          	/>,
		        	        ]);
		    	}},
	    }
    ];

    function atualizaDados() {
    	var getUrl = baseUrl + "/get-data-missas";

        axios.get(getUrl)
            .then(resp => { 
                setRows(resp.data); 
            });
    }

    useEffect(() => {

    	console.log("New value");
    	console.log(props.isEditMode);

    	if (props.isEditMode == true || rows.length == 0) {
    		atualizaDados();
    	}

    }, [props.isEditMode]);

    useEffect(() => {
    	if (props.isUpdateMode == false) {
    		cancelEditRow();
    	}
    }, [props.isUpdateMode]);

    useEffect(() => {

        var getUrl = baseUrl + "/get-tempos-liturgicos-with-id";

        axios.get(getUrl)
            .then(resp => { 
                setMissas(resp.data); 
            });
    }, []);

    if (props.isEditMode == true && props.isUpdateMode == false) {
    	return (
    		<DataGrid
    			autoHeight 
		        rows={rows}
		        columns={columns}
		        pageSizeOptions={[50]}
		        style={{width: "85%"}}
		    />
    	);
    } else
		return (
		<form noValidate onSubmit={() => console.log("oi turma")} id="formulario-login-cadastro-dados">
			<Autocomplete
				id="fixed-tags-demo"
				value={valuesDict["missa"]}
				onChange={(event, newValue) => {
					changeValues("missa", newValue);
				}}
				options={getOptions()}
				getOptionLabel={(option) => option.nome}
				style={{ width: "100%" }}
				renderInput={(params) => (
					<TextField {...params} 
						label="Título da missa" />
				)}
			/>
	        <LocalizationProvider dateAdapter={AdapterDayjs}>
	       		<DateCalendar value={valuesDict["data"]} onChange={(newValue) => changeValues("data", newValue)} />
	       	</LocalizationProvider>
		    <LoadingButton loading={loading} onClick={() => submitForm()} style={{backgroundColor: "#bc1200"}} variant="contained">
		    	Salvar dados
		    </LoadingButton>
		</form>
	);
}