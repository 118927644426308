
import {baseUrl} from "./adalConfig.js";

import axios from 'axios';

export const isAuthenticated = (token) => {
	var url = baseUrl + "/autenticar?token="+token;

	axios.get(url)
    .then(resp => {
        console.log(resp);
    });

}

export const logar = (login, senha) => {
	var url = baseUrl + "/logar?login="+login+"&senha="+senha;
}

