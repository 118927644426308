import React, {useState, useEffect} from 'react';
import line from "../../images/musica-liturgica/home/line.png";
import Divider from '@mui/material-next/Divider';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { NavLink, useNavigate } from 'react-router-dom';
// import ptBr from 'date-fns/locale/pt-br';
// with date-fns v3.x
import { pt } from 'date-fns/locale/pt';
import axios from 'axios';

// baseUrl
import {baseUrl} from "../../services/adalConfig.js";

import "./Musica.css";

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

export default function MusicaLiturgicaHome(props) {

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const [dictOcasioesSolenidades, setDictOcasioesSolenidades] = useState({"Solenidade": {}, "Ocasião Especial": {}});
    const [proximaMissa, setProximaMissa] = useState({"id": -1, "url": "", "nome": "", data: ""});
 
    useEffect(() => {
       function handleResize() {
         setWindowDimensions(getWindowDimensions());
       }
       window.addEventListener('resize', handleResize);
       return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        var date = new Date();
        date = date.toISOString().substring(0,10);

        var getUrl = baseUrl + "/datas-liturgicas?date=" + date;

        axios.get(getUrl)
            .then(resp => {
                if (resp.data != null && resp.data != "") {
                    setProximaMissa(resp.data);
                }
            });
    }, []);

    useEffect(() => {

        var getUrl = baseUrl + "/get-solenidades-e-festas";

        axios.get(getUrl)
            .then(resp => {
                setDictOcasioesSolenidades(resp.data); 
            });
    }, []);
    
    var dateHoje = new Date();
    dateHoje = dateHoje.toISOString().substring(8,10) + "/" + dateHoje.toISOString().substring(5,7) + "/" + dateHoje.toISOString().substring(0, 4);

    if (window.innerWidth <= 768) {
       return (<MusicaLiturgicaHomeMobile proximaMissa={proximaMissa} dateHoje={dateHoje} dictOcasioesSolenidades={dictOcasioesSolenidades} />);
    } else {
       return (<MusicaLiturgicaHomeDesktop proximaMissa={proximaMissa} dateHoje={dateHoje} dictOcasioesSolenidades={dictOcasioesSolenidades} />);
    }
}

function MusicaLiturgicaHomeDesktop(props) {

    const [value, setValue] = React.useState("");
    const navigate = useNavigate();

    function scrollTo(sessionClassname) {
        var element = document.getElementsByClassName(sessionClassname);
        element[0].scrollIntoView({ behavior: 'smooth' , block: "center", inline: "center"});
    }

    var solenityes = {};

    if (value != "" && value != null) {
        var listKeys = Object.keys(props.dictOcasioesSolenidades["Solenidade"]);
        for (let i = 0; i < listKeys.length; i++) {
            if (listKeys[i].toLowerCase().includes(value.toLowerCase())) {
                solenityes[listKeys[i]] = props.dictOcasioesSolenidades["Solenidade"][listKeys[i]]
            }
        }
    } else if (props.dictOcasioesSolenidades["Solenidade"] != null){
        solenityes = props.dictOcasioesSolenidades["Solenidade"];
    }

    console.log("Sol is");
    console.log(solenityes);
        
    return (
        <div style={{marginBottom: "100px", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", marginTop: "110px", overflow: "hidden"}}>
            <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                <h1 class="musicas-home-bordao" >“O próprio cantar é quase voar, elevar-se para Deus, antecipar de certa forma a eternidade, quando poderemos cantar continuamente os louvores de Deus.”</h1>
                <h2 class="musicas-home-bordao-subtitulo">Papa Bento XVI</h2>
            </div>
            <div class="musica-home-header">
                <Divider sx={{
                    "&::before, &::after": {
                    borderColor: "#bc1200",
                    },
                }}>
                    Música Litúrgica
                </Divider>
            </div>
            <div class="musica-home-opcoes">
                <div class="musica-home-opcoes-session" style={{width: "40%", justifyContent: "flex-start"}}>
                    {
                        (props.proximaMissa.id != -1)
                        &&
                        <div style={{ width: "80%", marginBottom: "35px"}}>
                            <div class="home-call-to-action" style={{margin: "0px"}}>
                                <NavLink to={"/musica-liturgica/missa/"+props.proximaMissa.url} style={{ color: "black"}}>
                                    <p style={{fontSize: "calc(0.7rem + 0.5vw)"}}><b><u>Repertório para a próxima solenidade: {props.proximaMissa.nome}</u></b></p><p style={{fontSize: "calc(0.7rem + 0.5vw)"}}>{props.proximaMissa.data.substring(8,10) + "/" + props.proximaMissa.data.substring(5,7) + "/" + props.proximaMissa.data.substring(0,4)}</p>
                                </NavLink>
                            </div>
                        </div>
                    }
                    <div style={{width: "100%", height: "25%", display: "flex", justifyContent: "center"}}>
                        <h3>Selecione o tempo litúrgico:</h3>
                    </div>
                        <div style={{display: "flex", flexDirection: "column", alignItems: "flex-start", width: "70%"}}>
                            <li class="home-musicas-item-list"><NavLink to="/musica-liturgica/advento" style={{color: "black", textDecoration: "none"}}>Advento</NavLink></li>
                            <li class="home-musicas-item-list"><NavLink to="/musica-liturgica/quaresma" style={{color: "black", textDecoration: "none"}}>Quaresma</NavLink></li>
                            <li class="home-musicas-item-list"><NavLink to="/musica-liturgica/pascoa" style={{color: "black", textDecoration: "none"}}>Páscoa</NavLink></li>
                            <li class="home-musicas-item-list"><NavLink to="/musica-liturgica/tempo-comum" style={{color: "black", textDecoration: "none"}}>Tempo Comum</NavLink></li>
                            <div style={{width: "100%", height: "15px"}}></div>
                            <div class="musicas-home-tematica" onClick={() => scrollTo("solenidades-festas")}>
                                <li style={{fontSize: "calc(0.8rem + 0.5vw)", textDecoration: "underline"}}>Solenidades, Festas e outras</li>
                                <p style={{fontSize: "calc(0.8rem + 0.5vw)"}}>(Hinos marianos, Festas de santos etc)</p>
                            </div>
                            <div style={{width: "100%", height: "5px"}}></div>
                            <div class="musicas-home-tematica" onClick={() => scrollTo("ocasioes-especiais")}>
                                <li style={{fontSize: "calc(0.8rem + 0.5vw)", textDecoration: "underline"}}>Ocasiões especiais</li>
                                <p style={{fontSize: "calc(0.8rem + 0.5vw)"}}>(Casamentos, Batismos etc)</p>
                            </div>

                        </div>
                </div>
                <div class="musica-home-opcoes-session" style={{width: "20%"}}>
                    <p>OU</p>
                </div>
                <div class="musica-home-opcoes-session" style={{width: "40%", justifyContent: "flex-start"}}>
                    <div style={{width: "100%", height: "25%", display: "flex", justifyContent: "center"}}>
                        <h3>Selecione a parte desejada:</h3>
                    </div>
                        <div style={{display: "flex", flexDirection: "column", alignItems: "flex-start", width: "70%"}}>
                            <li class="home-musicas-item-list"><NavLink to="/musica-liturgica/tema/entrada" style={{color: "black", textDecoration: "none"}}>Entrada</NavLink></li>
                            <li class="home-musicas-item-list"><NavLink to="/musica-liturgica/tema/ato-penitencial" style={{color: "black", textDecoration: "none"}}>Ato Penitencial</NavLink></li>
                            <li class="home-musicas-item-list"><NavLink to="/musica-liturgica/tema/gloria" style={{color: "black", textDecoration: "none"}}>Glória</NavLink></li>
                            <li class="home-musicas-item-list"><NavLink to="/musica-liturgica/tema/salmo" style={{color: "black", textDecoration: "none"}}>Salmo Responsorial</NavLink></li>
                            <li class="home-musicas-item-list"><NavLink to="/musica-liturgica/tema/ofertorio" style={{color: "black", textDecoration: "none"}}>Ofertório</NavLink></li>
                            <li class="home-musicas-item-list"><NavLink to="/musica-liturgica/tema/santo" style={{color: "black", textDecoration: "none"}}>Santo</NavLink></li>
                            <li class="home-musicas-item-list"><NavLink to="/musica-liturgica/tema/amem" style={{color: "black", textDecoration: "none"}}>Doxologia</NavLink></li>
                            <li class="home-musicas-item-list"><NavLink to="/musica-liturgica/tema/cordeiro-de-deus" style={{color: "black", textDecoration: "none"}}>Cordeiro de Deus</NavLink></li>
                            <li class="home-musicas-item-list"><NavLink to="/musica-liturgica/tema/comunhao" style={{color: "black", textDecoration: "none"}}>Comunhão</NavLink></li>
                        </div>
                </div>
            </div>
            <div class="musica-home-header">
                <Divider sx={{
                    "&::before, &::after": {
                    borderColor: "#bc1200",
                    },
                }}>
                    Solenidades e Festas
                </Divider>
            </div>
            <div style={{width: "50%", marginTop: "20px"}}>
                <div style={{width: "100%", height: "30%", display: "flex", marginBottom: "25px"}}>
                    <TextField 
                        sx={{
                            width: "100%"
                        }} 
                        label="Pesquisar..." 
                        value={value}
                        onChange={(event) => {
                            setValue(event.target.value);
                        }}
                    />
                </div>
                <ul>
                    {
                    Object.keys(solenityes).map(chave => {
                        if (chave != 'Domingo de Ramos') {
                            return (<li class="home-musicas-item-list"><NavLink to={solenityes[chave]} style={{color: "black", textDecoration: "none"}}>{chave}</NavLink></li>);
                        } else {
                            return (<li class="home-musicas-item-list solenidades-festas"><NavLink to={solenityes[chave]} style={{color: "black", textDecoration: "none"}}>{chave}</NavLink></li>);
                        }
                        
                    })
                    }
                </ul>
            </div>
            <div class="musica-home-header">
                <Divider sx={{
                    "&::before, &::after": {
                    borderColor: "#bc1200",
                    },
                }}>
                    Ocasiões Especiais
                </Divider>
            </div>
            <div style={{width: "50%", marginTop: "20px"}}>
                <ul>
                    {Object.keys(props.dictOcasioesSolenidades["Ocasião Especial"]).map(chave => {
                        if (chave != 'Crisma') {
                            return (<li class="home-musicas-item-list"><NavLink to={props.dictOcasioesSolenidades["Ocasião Especial"][chave]} style={{color: "black", textDecoration: "none"}}>{chave}</NavLink></li>);
                        } else {
                            return (<li class="home-musicas-item-list ocasioes-especiais"><NavLink to={props.dictOcasioesSolenidades["Ocasião Especial"][chave]} style={{color: "black", textDecoration: "none"}}>{chave}</NavLink></li>);
                        }
                        
                    })
                    }
                </ul>
            </div>
        </div>
    )
}

const partesMissaMobile = {
    "Entrada": "/musica-liturgica/tema/entrada",
    "Ato Penitencial": "/musica-liturgica/tema/ato-penitencial",
    "Glória": "/musica-liturgica/tema/gloria",
    "Salmo Responsorial": "/musica-liturgica/tema/salmo",
    "Ofertório": "/musica-liturgica/tema/ofertorio",
    "Santo": "/musica-liturgica/tema/santo",
    "Doxologia": "/musica-liturgica/tema/amem" ,
    "Cordeiro de Deus": "/musica-liturgica/tema/cordeiro-de-deus",
    "Comunhão": "/musica-liturgica/tema/comunhao"
}

function MusicaLiturgicaHomeMobile(props) {
    
    const [value, setValue] = React.useState("");
    const navigate = useNavigate();

    function scrollTo(sessionClassname) {
        var element = document.getElementsByClassName(sessionClassname);
        element[0].scrollIntoView({ behavior: 'smooth' , block: "center", inline: "center"});
    }

    var solenityes = {};

    if (value != "" && value != null) {
        var listKeys = Object.keys(props.dictOcasioesSolenidades["Solenidade"]);
        for (let i = 0; i < listKeys.length; i++) {
            if (listKeys[i].toLowerCase().includes(value.toLowerCase())) {
                solenityes[listKeys[i]] = props.dictOcasioesSolenidades["Solenidade"][listKeys[i]]
            }
        }
    } else if (props.dictOcasioesSolenidades["Solenidade"] != null){
        solenityes = props.dictOcasioesSolenidades["Solenidade"];
    }

    return <div style={{marginBottom: "100px", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", marginTop: "110px", overflow: "hidden"}}>
        <div style={{width: "90%", marginTop: "0px"}}>
                <div class="musica-home-header" style={{width: "100%", marginTop: "0px"}}>
                    <Divider sx={{
                        "&::before, &::after": {
                        borderColor: "#bc1200",
                        },
                    }}>
                        Música Litúrgica
                    </Divider>
                </div>
        </div>
        {
            (props.proximaMissa.id != -1)
            &&
            <div style={{ width: "90%", height: "70px", display: "flex", alignItems: "center",     marginTop: "20px"}}>
                <div class="home-call-to-action" style={{margin: "0px"}}>
                    <NavLink to={"/musica-liturgica/missa/"+props.proximaMissa.url} style={{ color: "black", textDecoration: "none"}}>
                        <p style={{lineHeight: "22px"}}>>> Repertório para a próxima solenidade: <b><u>{props.proximaMissa.nome}</u></b></p><p>{props.proximaMissa.data.substring(8,10) + "/" + props.proximaMissa.data.substring(5,7) + "/" + props.proximaMissa.data.substring(0,4)}</p>
                    </NavLink>
                </div>
            </div>
        }
        <div style={{width: "100%", display: "flex", flexDirection: "column", alignItems: "center", marginTop: "20px"}}>
            <div style={{width: "90%", marginBottom: "20px"}}>
                <h1 style={{fontSize: "34px", textAlign: "center", fontWeight: "100"}}> Partes da missa </h1>
            </div>
            <div style={{width: "95%"}}>
                {
                    Object.keys(partesMissaMobile ).map(item => {
                    if (item == "Entrada") {
                        return <NavLink to={partesMissaMobile[item]} style={{padding: "18px 0px", borderWidth: "1px 0px", borderColor: "#bc1200", borderStyle: "solid", width: "100%", display: "flex", justifyContent: "center", textDecoration: "none"}}><p style={{color: "black", textDecoration: "none", fontSize: "18px", fontWeight: "600"}}>{item}</p></NavLink>
                    } else {
                        return <NavLink style={{padding: "18px 0px", borderWidth: "0px 0px 1px 0px", borderColor: "#bc1200", borderStyle: "solid", width: "100%", display: "flex", justifyContent: "center", textDecoration: "none"}}><p to={partesMissaMobile[item]} style={{color: "black", textDecoration: "none", fontSize: "18px", fontWeight: "600"}}>{item}</p></NavLink>
                    }
    
})
                }
            </div>
        </div>
        <div style={{width: "100%", display: "flex", flexDirection: "column", alignItems: "center", marginTop: "40px"}}>
            <div style={{width: "90%", marginBottom: "30px"}}>
                <h1 style={{fontSize: "34px", textAlign: "center", fontWeight: "100"}}> Tempos Litúrgicos </h1>
            </div>
            <div style={{width: "100%"}}>
                <div style={{display: "flex", justifyContent: "space-around", marginBottom: "30px"}}>
                    <NavLink to="/musica-liturgica/advento"  className="musica-home-mobile-tempo-liturgico">Advento</NavLink>
                    <NavLink to="/musica-liturgica/tempo-comum" className="musica-home-mobile-tempo-liturgico">Tempo Comum</NavLink>
                </div>
                <div style={{display: "flex", justifyContent: "space-around", marginBottom: "30px"}}>
                    <NavLink to="/musica-liturgica/quaresma"  className="musica-home-mobile-tempo-liturgico">Quaresma</NavLink>
                    <NavLink to="/musica-liturgica/pascoa"  className="musica-home-mobile-tempo-liturgico">Páscoa</NavLink>
                </div>
                <div style={{display: "flex", justifyContent: "space-around", marginBottom: "30px"}}>
                    <p onClick={() => scrollTo("solenidades-festas")} className="musica-home-mobile-tempo-liturgico">Solenidades</p>
                    <p onClick={() => scrollTo("ocasioes-especiais")} className="musica-home-mobile-tempo-liturgico">Ocasiões Esp.</p>
                </div>
            </div>
        </div>
        <div style={{width: "100%", display: "flex", flexDirection: "column", alignItems: "center"}}>
            <div style={{width: "90%", marginBottom: "20px"}}>
                <h1 style={{fontSize: "34px", textAlign: "center", fontWeight: "100"}}> Solenidades e Festas </h1>
            </div>
            <div style={{width: "95%", display: "flex", flexDirection: "column", alignItems: "center"}}>
                <TextField 
                    sx={{
                        width: "80%",
                        marginBottom: "20px"
                    }} 
                    label="Pesquisar..." 
                    value={value}
                    onChange={(event) => {
                        setValue(event.target.value);
                    }}
                />
                {Object.keys(solenityes).map(chave => {
                        if (chave == 'Pentecostes') {
                            return <NavLink to={solenityes[chave]} className="solenidades-festas" style={{padding: "18px 0px", borderWidth: "0px 0px 1px 0px", borderColor: "#bc1200", borderStyle: "solid", width: "100%", display: "flex", justifyContent: "center", textDecoration: "none"}}><p style={{color: "black", textDecoration: "none", fontSize: "18px", fontWeight: "600"}}>{chave}</p></NavLink>
                        } else if (chave == "Natal") {
                            return <NavLink style={{padding: "18px 0px", borderWidth: "1px 0px", borderColor: "#bc1200", borderStyle: "solid", width: "100%", display: "flex", justifyContent: "center", textDecoration: "none"}}><p to={solenityes[chave]} style={{color: "black", textDecoration: "none", fontSize: "18px", fontWeight: "600"}}>{chave}</p></NavLink>
                        } else {
                            return <NavLink to={solenityes[chave]} style={{padding: "18px 0px", borderWidth: "0px 0px 1px 0px", borderColor: "#bc1200", borderStyle: "solid", width: "100%", display: "flex", justifyContent: "center", textDecoration: "none"}}><p style={{color: "black", textDecoration: "none", fontSize: "18px", fontWeight: "600"}}>{chave}</p></NavLink>
                        }
                        
                    })
                }
            </div>
        </div>
        <div style={{width: "100%", display: "flex", flexDirection: "column", alignItems: "center", marginTop: "30px"}}>
            <div style={{width: "90%", marginBottom: "20px"}}>
                <h1 style={{fontSize: "34px", textAlign: "center", fontWeight: "100"}}> Ocasiões Especiais </h1>
            </div>
            <div style={{width: "95%", display: "flex", flexDirection: "column", alignItems: "center"}}>
                {Object.keys(props.dictOcasioesSolenidades["Ocasião Especial"]).map(chave => {
                        if (chave == 'Casamento') {
                            return <NavLink to={props.dictOcasioesSolenidades["Ocasião Especial"][chave]} style={{padding: "18px 0px", borderWidth: "1px 0px", borderColor: "#bc1200", borderStyle: "solid", width: "100%", display: "flex", justifyContent: "center", textDecoration: "none"}}><p style={{color: "black", textDecoration: "none", fontSize: "18px", fontWeight: "600"}}>{chave}</p></NavLink>
                        } else if (chave == "Crisma") {
                            return <NavLink className="ocasioes-especiais" style={{padding: "18px 0px", borderWidth: "0px 0px 1px 0px", borderColor: "#bc1200", borderStyle: "solid", width: "100%", display: "flex", justifyContent: "center", textDecoration: "none"}}><p to={props.dictOcasioesSolenidades["Ocasião Especial"][chave]} style={{color: "black", textDecoration: "none", fontSize: "18px", fontWeight: "600"}}>{chave}</p></NavLink>
                        } else {
                            return <NavLink to={props.dictOcasioesSolenidades["Ocasião Especial"][chave]} style={{padding: "18px 0px", borderWidth: "0px 0px 1px 0px", borderColor: "#bc1200", borderStyle: "solid", width: "100%", display: "flex", justifyContent: "center", textDecoration: "none"}}><p style={{color: "black", textDecoration: "none", fontSize: "18px", fontWeight: "600"}}>{chave}</p></NavLink>
                        }
                        
                    })
                }
            </div>
        </div>
    </div>
}