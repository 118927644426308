import React, {useState, useEffect, useRef} from 'react';
import Divider from '@mui/material-next/Divider';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { NavLink } from 'react-router-dom';
import Button from '@mui/material/Button';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import ShareIcon from '@mui/icons-material/Share';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import IosShareIcon from '@mui/icons-material/IosShare';
import DraftsIcon from '@mui/icons-material/Drafts';
import SendIcon from '@mui/icons-material/Send';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import Slider from '@mui/material/Slider';
import Box from '@mui/material/Box';
import YouTubeIcon from '@mui/icons-material/YouTube';
import CloseIcon from '@mui/icons-material/Close';
import Stack from '@mui/material/Stack';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
// import ptBr from 'date-fns/locale/pt-b",r"';
// with date-fns v3.x
import { pt } from 'date-fns/locale/pt';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import YouTube from 'react-youtube';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import FormatSizeIcon from '@mui/icons-material/FormatSize';
import PrintIcon from '@mui/icons-material/Print';
import KeyboardDoubleArrowDownSharpIcon from '@mui/icons-material/KeyboardDoubleArrowDownSharp';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import {useReactToPrint, ReactToPrint} from "react-to-print";
import FeaturedVideoIcon from '@mui/icons-material/FeaturedVideo';

import line from "../../images/musica-liturgica/home/line.png";
import halfIcon from "../../images/musica-liturgica/half.png";
import turtle from "../../images/musica-liturgica/turtle.png";
import rabbit from "../../images/musica-liturgica/rabbit.png";

// baseUrl
import {baseUrl} from "../../services/adalConfig.js";

import axios from "axios";

import "./Musica.css";

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

const dictTomMaior = {
	"C": "C#", 
	"C#": "D", 
	"D": "D#", 
	"D#": "E",
	"E": "F", 
	"F": "F#", 
	"F#": "G", 
	"G": "G#", 
	"G#": "A", 
	"A": "A#", 
	"A#": "B", 
	"B": "C",
	"Cb": "C", 
	"Db": "D", 
	"Eb": "E", 
	"Fb": "F", 
	"Gb": "G", 
	"Ab": "A", 
	"Bb": "B",
	};

const dictTomMenor = {
  "C#": "C",
  "D": "C#",
  "D#": "D",
  "E": "D#",
  "F": "E",
  "F#": "F",
  "G": "F#",
  "G#": "G",
  "A": "G#",
  "A#": "A",
  "B": "A#",
  "C": "B",
  "Cb": "Bb", 
  "Db": "C", 
  "Eb": "D", 
  "Fb": "E", 
  "Gb": "F", 
  "Ab": "G", 
  "Bb": "A",
};

export default function TempoLiturgico(props) {

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const [musica, setMusica] = useState({"nome":"","autor":"","cifra":""});

 
    useEffect(() => {
       function handleResize() {
         setWindowDimensions(getWindowDimensions());
       }
       window.addEventListener('resize', handleResize);
       return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        var url = window.location.href;

        var l = url.split("/");
        var tempMusica = l[l.length - 1];

        var getUrl = baseUrl + "/dados-musica?url="+tempMusica

        axios.get(getUrl)
        	.then(resp => {
        		if (resp.data.length == 0) {
        			setMusica(false);
        		} else {
        			var tempData = resp.data;
        			tempData.cifra = tempData.cifra.replaceAll("\n", "\\n");
        			setMusica(resp.data);
        		}
        	});
    }, []);

    if (musica == "" || musica == false) return <div></div>;
 
    if (window.innerWidth <= 768) {
       return (<MusicaPageMobile musica={musica}/>);
    } else {
       return (<MusicaPageDesktop musica={musica}/>);
    }
}

function AutoRolagemPopOver(props) {

	const [pacing, setPacing] = useState(false);
	const [velocidade, setVelocidade] = useState(50);
	const [pause, setPause] = useState(false);

	useEffect(() => {

		if (props.isAutoScrolling == true) {
			setTimeout(() => {
			    movePage(1);
			}, 130 - velocidade);
		}

	}, [props.isAutoScrolling, pacing]);

	function movePage(speed) {
		if (pause == true) {
			setPacing(!pacing);
			return;
		}
		requestAnimationFrame(() => {
			window.scrollBy({top: speed, behavior : "smooth"});
			if (props.isAutoScrolling == true) setPacing(!pacing);
		})
	}

	function HandleAumentarVelocidade(num) {
		if (velocidade + num > 100) {
			setVelocidade(100);
		} else if (velocidade + num < 0) {
			setVelocidade(0);
		} else {
			setVelocidade(velocidade + num);
		}
	}
	
	return (<Popper
          open={props.isAutoScrolling}
          //anchorEl={anchorRef.current}
          role={undefined}
          style={{position: "fixed", bottom: "20px", left: "auto", "top": "auto"}}
          transition
          disablePortal
          sx={{backgroundColor: "white"}}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom-start' ? 'left top' : 'left bottom',
              }}
            >
              <Paper style={{width: "fit-content", backgroundColor: "white", paddingRight: "15px", paddingLeft: "15px"}}>
                <ClickAwayListener style={{width: "fit-content"}} onClickAway={() => console.log("oi")}>

	              	<Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center" style={{width: "400px", backgroundColor: "white", display: "flex"}} 
	              		sx={{
	              			'& .MuiSlider-thumb': {
							    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
							      boxShadow: 'inherit',
							    },
							    '&::before': {
							      display: 'none',
							    },
							}
						}}
					>
	              		<div style={{display: "flex", alignitems: "center"}}>
	              			<CloseIcon style={{width: "17px", height: "17px", cursor: "pointer"}} onClick={() => props.setIsAutoScrolling(false)}/>
	              		</div>
				        <>
					        <div style={{display: "flex", alignItems: "center"}}>
								<RemoveIcon style={{cursor: "pointer"}} onClick={() => HandleAumentarVelocidade(-20)}/>
							</div>
					        <Slider
							  size="small"
							  defaultValue={20}
							  aria-label="Small"
							  style={{color: "#bc1200"}}
							  value={velocidade}
							  onChange={(event) => setVelocidade(event.target.value)}
							/>
							<div style={{display: "flex", alignItems: "center"}}>
								<AddIcon style={{cursor: "pointer"}} onClick={() => HandleAumentarVelocidade(20)}/>
							</div>
						</>
						<div style={{display: "flex", alignitems: "center"}}>
							{
								(pause == true
								&&
								<PlayArrowIcon style={{width: "17px", height: "17px", cursor: "pointer"}} onClick={() => setPause(!pause)}/>)
								||
								(<PauseIcon style={{width: "17px", height: "17px", cursor: "pointer"}} onClick={() => setPause(!pause)}/>)
							}
	              		</div>
				    </Stack>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>)
}

function MusicaPageDesktop(props) {

	const [tamanhoFonte, setTamanhoFonte] = useState(24);
	const [cifraMusic, setCifraMusic] = useState("");
	const [numeroTom, setNumeroTom] = useState(0);
	const [isAutoScrolling, setIsAutoScrolling] = useState(false);
	const [open, setOpen] = useState(false);
	const ref = useRef();

	const opts = {
	        height: '200px',
	        width: '100%',
	        playerVars: {
	          // https://developers.google.com/youtube/player_parameters
	          autoplay: 0,
	        },
	};

	useEffect(() => {
		setCifraMusic(props.musica.cifra);
	}, [props.musica.cifra]);

	function generateCifra(cifra) {

		var tempCifra = cifra.split("}");

		for (let i = 0; i < tempCifra.length - 1; i++) {
			tempCifra[i] = tempCifra[i] + "}";
		}

		var tempList = [];

		const regexAcorde = /^.*?(?=\{)/;
		const regexLetra = /(?<=\{).*?(?=\})/;
		var numeroNovasLinhas;

		for (let i = 0; i < tempCifra.length - 1; i++) {

			const acorde = tempCifra[i].match(regexAcorde)[0];
			var letra = tempCifra[i].match(regexLetra)[0];

			const matches = letra.match(/\\n/g);

			const quantidadePularLinha = matches ? matches.length : 0;

			letra = letra.replaceAll("\\n", "");

			var primeiroCaractere = acorde;

			if (primeiroCaractere.substring(0,1) == "(") {

				var descricaoPassagem = letra;
				var acordePassagem = acorde.substring(1, acorde.length-1).replaceAll(' ', "   ");

				var element = <>
							<span className="musicas-page-bloco-cifra-letra" style={{flexDirection: "row", width: "100%", alignItems: "center"}}>
								<h6 className="musicas-page-letra-musica">{descricaoPassagem}:</h6>
								<span>&nbsp;&nbsp;</span>
								<h6 className="musicas-page-acorde">{acordePassagem}</h6>
							</span>
						<br/>
					</>;
			} else {

				if (letra == "\\l") {
					var element = <Divider style={{width: "100%", margin: "20px 0"}} sx={{
                    borderColor: "#bc1200"
                    }} />
				} else {
					letra = letra.split(/(<strong>.*?<\/strong>)/).map(segment => {
	                    if (segment.includes('strong')) {
	                    const text = segment.replace('<strong>','').replace('</strong>', '');
	                    return <strong>{text}</strong>;
	                    } else {
	                    return segment;
	                    }
                    });
                    var element = 
					<>
						<span className="musicas-page-bloco-cifra-letra">
							<h6 className="musicas-page-acorde" style={acorde == "*" ?{visibility: "hidden"}: {}}>{acorde}</h6>
							<h6 className="musicas-page-letra-musica">{letra}</h6>
						</span>
						{Array(quantidadePularLinha).fill(<div style={{width: "100%"}}></div>)}
					</>;
				}
				
			}
			tempList.push(element);
		}

		return tempList;

	}

	function pegarNota(nota) {
	    
	    const notaPrincipal = nota.replace(/\d+|m$/, '');

	    return notaPrincipal;
	}

	function getPrintContent() {

		return (
		<>
			<div style={{display: "flex", flexDirection: "column", alignItems: "center", marginBottom: "15px"}}>
				<div class="musica-home-header" style={{marginTop: "20px", marginBottom: "8px"}}>
                    <Divider sx={{
                        "&::before, &::after": {
                        borderColor: "#bc1200",
                        },
                    }}>
                        {props.musica.nome}
                    </Divider>
                </div>
                <h2 style={{fontWeight: 50}}>{props.musica.autor}</h2>
            </div>
        	<div id="font-change-id" style={{marginLeft: "20px", paddingTop: "10px", display: "flex", flexWrap: "wrap", fontSize: "23px"}}>
				{generateCifra(cifraMusic)}
			</div>
        </>
		)
	}

	function handleChangeTom(tom) {

		setNumeroTom(numeroTom + tom * 0.5);

		var tempCifra = cifraMusic;

		var regexNotasComuns = new RegExp("[A-G][#|b]?((m|7|\(b5\)|maj7|(6/(A|B|C|D|E|F))|(/(A|B|C|D|E|F))|6)+)?(?=\{)", "g");
		var regexPassagens = /\(([A-G][#|b|m|\+|7|maj7|\s|\(b5\)]+)+[A-G][#|b|m|\+|7|maj7|(6\/\[ABCDEFG\])|(\/\[ABCDEFG\])|6\s]?\)(?=\{)/;
		var regexApenasAcorde = /^([A-G](?:[#b]?))(.*)/;

		var dictToUse;
		if (tom == +1) {
			dictToUse = dictTomMaior;
		} else {
			dictToUse = dictTomMenor;
		}

		var acordeSeparado;
		var complemento;
		let novoTexto = tempCifra.replace(regexNotasComuns, function(acordeCompleto) {

			console.log("Acorde completo é");
			console.log(acordeCompleto);

		    acordeSeparado = acordeCompleto.match(regexApenasAcorde)[1];
		    complemento = acordeCompleto.match(regexApenasAcorde)[2];

		    // Retorna a substituição correspondente para o match atual
		    return dictToUse[acordeSeparado] + complemento;
		});

		var regexCadaAcorde = /[A-G][#|b]?((m|\+|7|\(b5\)|maj7|(6\/(A|B|C|D|E|F))|(\/(A|B|C|D|E|F))|6)+)?/gi;
		novoTexto = novoTexto.replace(regexPassagens, function(acordeCompleto) {

			console.log("Is this love");
			console.log(acordeCompleto);

			var result = acordeCompleto.replace(regexCadaAcorde, function(cadaAcordeCompleto) {

				console.log("Match is");
			    console.log(cadaAcordeCompleto);
			    acordeSeparado = cadaAcordeCompleto.match(regexApenasAcorde)[1];
			    complemento = cadaAcordeCompleto.match(regexApenasAcorde)[2];

			    console.log("Separado é");
			    console.log(cadaAcordeCompleto.match(regexApenasAcorde));

			    // Retorna a substituição correspondente para o match atual
			    return dictToUse[acordeSeparado] + complemento;

			});

		    return result;
		});

		setCifraMusic(novoTexto);

	}

	function handleFontSizeChange(numeroPx) {
		if (tamanhoFonte + numeroPx > 8 && tamanhoFonte + numeroPx < 40)
			setTamanhoFonte(tamanhoFonte + numeroPx);
	}

	function handleCopy() {
		navigator.clipboard.writeText(window.location.href);
		setOpen(true);
	}

	const handleClose = () => {
		setOpen(false);
	}

	var fontStyle = {
		marginLeft: "20px", 
		paddingTop: "10px", 
		display: "flex", 
		flexWrap: "wrap", 
		fontSize: tamanhoFonte + "px"
	};

    return  <div style={{marginBottom: "100px", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", marginTop: "90px"}}>
    			<Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
			        <Alert
			          onClose={handleClose}
			          severity="success"
			          variant="filled"
			          sx={{ width: '100%' }}
			        >
			         	Link copiado para a área de transferência!
			        </Alert>
		     	</Snackbar>
    			<AutoRolagemPopOver isAutoScrolling={isAutoScrolling} setIsAutoScrolling={setIsAutoScrolling}/>
                <div class="musica-home-header" style={{marginTop: "20px", marginBottom: "8px"}}>
                    <Divider sx={{
                        "&::before, &::after": {
                        borderColor: "#bc1200",
                        },
                    }}>
                        {props.musica.nome}
                    </Divider>
                </div>
                <div style={{display: "none"}}>
                	<div ref={ref}>
                	{getPrintContent()}
                	</div>
                </div>
                <h2 style={{fontWeight: 50}}>{props.musica.autor}</h2>
                <div style={{width: "90%", display: "flex", marginTop: "30px"}}>
                	<div className="musicas-page-side-bar">
	                	<Box
						    sx={{
						        display: 'flex',
						        flexDirection: 'column',
						        alignItems: 'center',
						        border: '1px solid',
						        borderColor: 'divider',
						        borderRadius: 2,
						        bgcolor: 'background.paper',
						        color: 'text.secondary',
						        '& svg': {
						            m: 1,
						        },
						    }}
						    style={{ display: "flex", justifyContent: "center", cursor: "pointer"}}
						    >
						    	<Box style={{display: "flex", alignItems: "center"}} onClick={() => setIsAutoScrolling(!isAutoScrolling)}>
						    		<KeyboardDoubleArrowDownSharpIcon style={{margin: "0px", marginRight: "4px"}}/>
						    		<p style={{fontSize: "14px", color: "black"}}>Auto rolagem</p>
						    	</Box>
						    </Box>
						    <Box
							    sx={{
							        display: 'flex',
							        flexDirection: 'column',
							        alignItems: 'center',
							        border: '1px solid',
							        borderColor: 'divider',
							        borderRadius: 2,
							        bgcolor: 'background.paper',
							        color: 'text.secondary',
							        '& svg': {
							            m: 1,
							        },
							    }}
							    style={{ display: "flex", justifyContent: "center"}}
						    >
						    	<Box style={{display: "flex"}}>
						    		<RemoveIcon style={{cursor: "pointer"}} onClick={() => handleFontSizeChange(-1)}/>
						    		<Divider orientation="vertical" variant="middle" flexItem />
						    		<FormatSizeIcon/>
						    		<Divider orientation="vertical" variant="middle" flexItem />
						    		<AddIcon style={{cursor: "pointer"}} onClick={() => handleFontSizeChange(+1)}/>
						    	</Box>
						    </Box>
						    <Box
							    sx={{
							        display: 'flex',
							        flexDirection: 'column',
							        alignItems: 'center',
							        border: '1px solid',
							        borderColor: 'divider',
							        borderRadius: 2,
							        bgcolor: 'background.paper',
							        color: 'text.secondary',
							        '& svg': {
							            m: 1,
							        },
							    }}
							    style={{cursor: "pointer"}}
						    >
						    	<ReactToPrint
							        bodyClass="print-agreement"
							        content={() => ref.current}
							        trigger={() => (
							             <PrintIcon/>
							         )}
							    />
						    </Box>
						    <Box
							    sx={{
							        display: 'flex',
							        flexDirection: 'column',
							        alignItems: 'center',
							        border: '1px solid',
							        borderColor: 'divider',
							        borderRadius: 2,
							        bgcolor: 'background.paper',
							        color: 'text.secondary',
							        '& svg': {
							            m: 1,
							        },
							    }}
							    style={{ display: "flex", justifyContent: "center"}}
						    >
						    	<Box style={{display: "flex"}}>
						    		<RemoveIcon  style={{margin: "0px", cursor: "pointer"}} onClick={() => handleChangeTom(-1)}/>
						    		<Divider orientation="vertical" variant="middle" flexItem style={{margin: "0px"}} />
						    		<Box style={{display: "flex", alignItems: "center", margin: "0px 6px 0px 6px"}}>
						    			{numeroTom == 0?
						    			<>
						    				<img style={{width: "18px", height: "18px"}} src={halfIcon}/>
							    			<p style={{color: "black", fontSize: "14px"}}>Tom</p>
						    			</>
						    			:
						    			<>
						    				<p style={{color: "black", width: "47.11px", textAlign: "center"}}>{numeroTom > 0? "+ ": "- "}{Math.abs(numeroTom)}</p>
						    			</>
						    			}
							    		
						    		</Box>
						    		<Divider orientation="vertical" variant="middle" flexItem  style={{margin: "0px"}} />
						    		<AddIcon  style={{margin: "0px", cursor: "pointer"}}  onClick={() => handleChangeTom(+1)}/>
						    	</Box>
						</Box>
						<Box
						    sx={{
						        display: 'flex',
						        flexDirection: 'column',
						        alignItems: 'center',
						        border: '1px solid',
						        borderColor: 'divider',
						        borderRadius: 2,
						        bgcolor: 'background.paper',
						        color: 'text.secondary',
						        '& svg': {
						            m: 1,
						        },
						    }}
						    style={{display: "flex", justifyContent: "center",  flexDirection: "row", cursor: "pointer"}}
						    onClick={() => handleCopy()}
					    >
				    		<ShareIcon style={{margin: "0px 4px 0px 0px"}} />
				    		<p style={{fontSize: "14px", color: "black"}}>Compartilhar</p>
				    	</Box>
	                </div>
	                <div style={{width: "60%"}}>
	                	<div id="font-change-id" style={fontStyle}>
							{generateCifra(cifraMusic)}
							
						</div>
	                </div>
	                {
	                	(props.musica.url_youtube != "false")
	                	&&
	                	<div style={{width: "30%", marginTop: "10px"}}>
			                {
			            		((props.musica.link_partitura != 'false')
			            		&&
			            		<div style={{height: "50px", display: "flex", width: "80%", alignItems: "center"}}>
			            			<a target="_blank" href={props.musica.link_partitura} style={{color: "#bc1200", fontWeight: "bold", fontSize: "19px"}}>Baixe a partitura</a>
			            		</div>)
			                }
		                	<div style={{display: "flex", justifyContent: "center"}}>
		                		<div style={{width: "100%", height: "100%"}}>
					                <YouTube videoId={`${props.musica.url_youtube}`} opts={opts}/>
					            </div>
		                	</div>
		                </div>
	                }
	            </div>
            </div>;
}

function PopOverMobile(props) {

	const [pacing, setPacing] = useState(false);
	const [velocidade, setVelocidade] = useState(50);
	const [pause, setPause] = useState(false);
	const [isAutoScrolling, setIsAutoScrolling] = useState(false);

	useEffect(() => {

		if (isAutoScrolling == true) {
			setTimeout(() => {
			    movePage(1);
			}, 130 - velocidade);
		}

	}, [isAutoScrolling, pacing]);

	function movePage(speed) {
		if (pause == true) {
			setPacing(!pacing);
			return;
		}
		requestAnimationFrame(() => {
			window.scrollBy({top: speed, behavior : "smooth"});
			if (isAutoScrolling == true) setPacing(!pacing);
		})
	}

	function HandleAumentarVelocidade(num) {
		if (velocidade + num > 100) {
			setVelocidade(100);
		} else if (velocidade + num < 0) {
			setVelocidade(0);
		} else {
			setVelocidade(velocidade + num);
		}
	}

	var centralElement;

	if (isAutoScrolling) {
		centralElement = <>
			<div style={{width: "2%"}}></div>
			<div style={{display: "flex", alignitems: "center"}}>
      			<CloseIcon style={{width: "17px", height: "17px", cursor: "pointer"}} onClick={() => setIsAutoScrolling(false)}/>
      		</div>
	        <>
		        <div style={{display: "flex", alignItems: "center"}}>
					<RemoveIcon style={{cursor: "pointer"}} onClick={() => HandleAumentarVelocidade(-20)}/>
				</div>
		        <Slider
				  size="small"
				  defaultValue={20}
				  aria-label="Small"
				  style={{color: "#bc1200"}}
				  value={velocidade}
				  onChange={(event) => setVelocidade(event.target.value)}
				/>
				<div style={{display: "flex", alignItems: "center"}}>
					<AddIcon style={{cursor: "pointer"}} onClick={() => HandleAumentarVelocidade(20)}/>
				</div>
			</>
			<div style={{display: "flex", alignitems: "center"}}>
				{
					(pause == true
					&&
					<PlayArrowIcon style={{width: "17px", height: "17px", cursor: "pointer"}} onClick={() => setPause(!pause)}/>)
					||
					(<PauseIcon style={{width: "17px", height: "17px", cursor: "pointer"}} onClick={() => setPause(!pause)}/>)
				}
      		</div>
      		<div style={{width: "2%"}}></div>
	    </>;         
	} else {
		centralElement = <div style={{ display: "flex", justifyContent: "space-between", width: "100%", marginLeft: "20px", marginRight: "20px", alignItems: "center", height: "100%"}}>
	    	<Box style={{height: "100%", display: "flex", alignItems: "center"}} onClick={() => setIsAutoScrolling(true)}>
	    		<p style={{color: "black"}}>Rolagem</p>
	    	</Box>
	    	<Box style={{height: "70%%", display: "flex", alignItems: "center"}}>
	    		<RemoveIcon  style={{margin: "0px", cursor: "pointer"}} onClick={() => props.handleChangeTom(-1)}/>
	    		<Divider orientation="vertical" variant="middle" flexItem style={{margin: "0px"}} />
	    		<Box style={{display: "flex", alignItems: "center", margin: "0px 6px 0px 6px"}}>
	    			{props.numeroTom == 0?
	    			<>
		    			<p style={{color: "black", width: "50px", textAlign: "center"}}>Tom</p>
	    			</>
	    			:
	    			<>
	    				<p style={{color: "black", width:"50px", textAlign: "center"}}>{props.numeroTom > 0? "+ ": "- "}{Math.abs(props.numeroTom)}</p>
	    			</>
	    			}
		    		
	    		</Box>
	    		<Divider orientation="vertical" variant="middle" flexItem  style={{margin: "0px"}} />
	    		<AddIcon  style={{margin: "0px", cursor: "pointer"}}  onClick={() => props.handleChangeTom(+1)}/>
	    	</Box>
	    	<Box  style={{height: "100%", display: "flex", alignItems: "center", width: "70px", justifyContent: "center"}} onClick={() => props.setShowVideo(!props.showVideo)}>
	    		{
	    			(props.showVideo) ?
	    			<FeaturedVideoIcon/>
	    			:
	    			<>
						<YouTubeIcon/>
		    			<p style={{color: "black", marginLeft: "3px"}}>Vídeo</p>
	    			</>
	    		}
	    	</Box>
	    </div>;
	}

	return (
			<Popper
	          open={true}
	          //anchorEl={anchorRef.current}
	          role={undefined}
	          style={{position: "fixed", bottom: "20px", left: "auto", "top": "auto", width: "90%"}}
	          transition
	          disablePortal
	          sx={{backgroundColor: "white"}}
	        >
	          {({ TransitionProps, placement }) => (
	            <Grow
	              {...TransitionProps}
	              style={{
	                transformOrigin:
	                  placement === 'bottom-start' ? 'left top' : 'left bottom',
	              }}
	            >
	              <Paper style={{width: "fit-content", backgroundColor: "white", paddingRight: "0px", paddingLeft: "0px", width: "100%"}}>
	                <ClickAwayListener style={{width: "fit-content"}} onClickAway={() => console.log("oi")}>

		              	<Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center" style={{backgroundColor: "white", display: "flex", width: "100%", height: "50px", alignItems: "center"}} 
		              		sx={{
		              			'& .MuiSlider-thumb': {
								    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
								      boxShadow: 'inherit',
								    },
								    '&::before': {
								      display: 'none',
								    },
								}
							}}
						>
		              	{centralElement}
					    </Stack>
	                </ClickAwayListener>
	              </Paper>
	            </Grow>
	          )}
	        </Popper>);	
}

function VideoPopOver(props) {
	
	const opts = {
	        height: '200px',
	        width: '100%',
	        playerVars: {
	          // https://developers.google.com/youtube/player_parameters
	          autoplay: 0,
	        },
	};

	var display = "none";

	if (props.showVideo) display = "block";

	return <div style={{width: "100%", display: display}}>
		<div style={{height: opts.height}}></div>
		<div style={{position: "fixed", left: "auto", top: "70px", width: "100%"}}>
			<YouTube videoId={`${props.url_youtube}`} opts={opts}/>
			{/*<div style={{display: "flex", justifyContent: "center"}}>
				<div style={{paddingRight: "20px", paddingLeft: "20px"}} onClick={() => props.setShowVideo(false)}>
					<ArrowBackIosNewIcon style={{rotate: "90deg"}}/>
				</div>
			</div>*/}
		</div>
	</div>;

}

function MusicaPageMobile(props) {

    const [tamanhoFonte, setTamanhoFonte] = useState(15);
	const [cifraMusic, setCifraMusic] = useState("");
	const [numeroTom, setNumeroTom] = useState(0);
	const [isAutoScrolling, setIsAutoScrolling] = useState(false);
	const [showVideo, setShowVideo] = useState(false);
	const ref = useRef();

	const opts = {
	        height: '300px',
	        width: '100%',
	        playerVars: {
	          // https://developers.google.com/youtube/player_parameters
	          autoplay: 0,
	        },
	};

	useEffect(() => {
		setCifraMusic(props.musica.cifra);
	}, [props.musica.cifra]);

	function generateCifra(cifra) {

		var tempCifra = cifra.split("}");

		for (let i = 0; i < tempCifra.length - 1; i++) {
			tempCifra[i] = tempCifra[i] + "}";
		}

		var tempList = [];

		const regexAcorde = /^.*?(?=\{)/;
		const regexLetra = /(?<=\{).*?(?=\})/;
		var numeroNovasLinhas;

		for (let i = 0; i < tempCifra.length - 1; i++) {

			const acorde = tempCifra[i].match(regexAcorde)[0];
			var letra = tempCifra[i].match(regexLetra)[0];

			const matches = letra.match(/\\n/g);

			const quantidadePularLinha = matches ? matches.length : 0;

			letra = letra.replaceAll("\\n", "");

			var primeiroCaractere = acorde;

			if (primeiroCaractere.substring(0,1) == "(") {

				var descricaoPassagem = letra;
				var acordePassagem = acorde.substring(1, acorde.length-1).replaceAll(' ', "   ");

				var element = <>
							<span className="musicas-page-bloco-cifra-letra" style={{flexDirection: "row", width: "100%", alignItems: "center"}}>
								<p className="musicas-page-letra-musica">{descricaoPassagem}:</p>
								<span>&nbsp;&nbsp;</span>
								<p className="musicas-page-acorde">{acordePassagem}</p>
							</span>
						<br/>
					</>;
			} else {
				var element = 
					<>
						<span className="musicas-page-bloco-cifra-letra">
							<p className="musicas-page-acorde">{acorde}</p>
							<p className="musicas-page-letra-musica">{letra}</p>
						</span>
						{Array(quantidadePularLinha).fill(<div style={{width: "100%"}}></div>)}
					</>
				
			}
			tempList.push(element);
		}

		return tempList;

	}

	function pegarNota(nota) {
	    
	    const notaPrincipal = nota.replace(/\d+|m$/, '');

	    return notaPrincipal;
	}

	function getPrintContent() {

		return (
		<>
			<div style={{display: "flex", flexDirection: "column", alignItems: "center", marginBottom: "15px"}}>
				<div class="musica-home-header" style={{marginTop: "20px", marginBottom: "8px"}}>
                    <Divider sx={{
                        "&::before, &::after": {
                        borderColor: "#bc1200",
                        },
                    }}>
                        {props.musica.nome}
                    </Divider>
                </div>
                <h2 style={{fontWeight: 50}}>{props.musica.autor}</h2>
            </div>
        	<div id="font-change-id" style={{marginLeft: "20px", paddingTop: "10px", display: "flex", flexWrap: "wrap", fontSize: "23px"}}>
				{generateCifra(cifraMusic)}
			</div>
        </>
		)
	}

	function handleChangeTom(tom) {

		setNumeroTom(numeroTom + tom * 0.5);

		var tempCifra = cifraMusic;

		var regexNotasComuns = new RegExp("[A-G][#|b]?((m|7|\(b5\)|maj7|(6/(A|B|C|D|E|F))|(/(A|B|C|D|E|F))|6)+)?(?=\{)", "g");
		var regexPassagens = /\(([A-G][#|b|m|\+|7|maj7|\s|\(b5\)]+)+[A-G][#|b|m|\+|7|maj7|(6\/\[ABCDEFG\])|(\/\[ABCDEFG\])|6\s]?\)(?=\{)/;
		var regexApenasAcorde = /^([A-G](?:[#b]?))(.*)/;

		var dictToUse;
		if (tom == +1) {
			dictToUse = dictTomMaior;
		} else {
			dictToUse = dictTomMenor;
		}

		var acordeSeparado;
		var complemento;
		let novoTexto = tempCifra.replace(regexNotasComuns, function(acordeCompleto) {

			console.log("Acorde completo é");
			console.log(acordeCompleto);

		    acordeSeparado = acordeCompleto.match(regexApenasAcorde)[1];
		    complemento = acordeCompleto.match(regexApenasAcorde)[2];

		    // Retorna a substituição correspondente para o match atual
		    return dictToUse[acordeSeparado] + complemento;
		});

		var regexCadaAcorde = /[A-G][#|b]?((m|\+|7|\(b5\)|maj7|(6\/(A|B|C|D|E|F))|(\/(A|B|C|D|E|F))|6)+)?/gi;
		novoTexto = novoTexto.replace(regexPassagens, function(acordeCompleto) {

			console.log("Is this love");
			console.log(acordeCompleto);

			var result = acordeCompleto.replace(regexCadaAcorde, function(cadaAcordeCompleto) {

				console.log("Match is");
			    console.log(cadaAcordeCompleto);
			    acordeSeparado = cadaAcordeCompleto.match(regexApenasAcorde)[1];
			    complemento = cadaAcordeCompleto.match(regexApenasAcorde)[2];

			    console.log("Separado é");
			    console.log(cadaAcordeCompleto.match(regexApenasAcorde));

			    // Retorna a substituição correspondente para o match atual
			    return dictToUse[acordeSeparado] + complemento;

			});

		    return result;
		});

		setCifraMusic(novoTexto);

	}

	function handleFontSizeChange(numeroPx) {
		if (tamanhoFonte + numeroPx > 8 && tamanhoFonte + numeroPx < 30) {
			setTamanhoFonte(tamanhoFonte + numeroPx);
		}
	}

	var fontStyle = {
		marginLeft: "20px", 
		paddingTop: "10px", 
		display: "flex", 
		flexWrap: "wrap", 
		fontSize: tamanhoFonte + "px"
	};

	function handleShare() {
		navigator.share({
	    title: `${props.musica.nome} - ${props.musica.autor}`,
	    url: window.location.href
	  })
	  .then(() => console.log('Successful share'))
	  .catch(error => console.log('Error sharing:', error));
	}

    return  <div style={{marginBottom: "100px", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", marginTop: "90px", overflow: "hidden"}}>
    			<PopOverMobile handleChangeTom={handleChangeTom} numeroTom={numeroTom} setShowVideo={setShowVideo} showVideo={showVideo}/>
    			<VideoPopOver url_youtube={props.musica.url_youtube} showVideo={showVideo} setShowVideo={setShowVideo}/>

    			<div style={{marginTop: "20px", display: "flex", flexDirection: "row",    alignItems: "stretch", width: "100%"}}>
    				<div style={{width: "10%"}}>
	                	<IosShareIcon style={{visibility: "hidden"}}/>
	                </div>  
	                <div class="musica-home-header" style={{margin: "0px", width: "80%",    display: "flex", flexDirection: "column", alignItems: "center"}}>
	                    <p style={{textAlign: "center", fontSize: "4.5vw"}}>{props.musica.nome}</p>
	                    <h2 
		                	style={{
			                    fontSize: "4.0vw"
			                }}>
			            {props.musica.autor}
			        </h2>
	                </div>
	                <div onClick={() => handleShare()} style={{width: "10%", display: "flex", alignItems: "center"}}>
	                	<IosShareIcon/>
	                </div>  
			    </div>
                <div style={{width: "100%", display: "flex", flexDirection: "column", alignItems: "center", marginTop: "15px"}}>
                	{
            			((props.musica.link_partitura != 'false')
	            			&&
	            		<div style={{width: "95%"}}>
	            			<div style={{marginLeft: "20px"}}>
	            				<a target="_blank" href={props.musica.link_partitura} style={{color: "#bc1200", fontWeight: "bold", fontSize: "16px"}}>Baixe a partitura</a>
	            			</div>
	            		</div>)
	                }
	                <div style={{width: "95%"}}>
	                	<div id="font-change-id" style={fontStyle}>
							{generateCifra(cifraMusic)}
							
						</div>
	                </div>
	            </div>
            </div>;
}