
export let baseUrl = "";

if (window.location.href.includes("http://localhost:3000/")) {
	baseUrl = "http://localhost:3001";
	//baseUrl = "https://portal-da-liturgia-back.vercel.app";
} else {
	baseUrl = "https://portal-da-liturgia-back.vercel.app";
}






