import {useState, useEffect} from 'react';

import TextField from '@mui/material/TextField';
import EditIcon from '@mui/icons-material/Edit';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from "react-router-dom";

// baseUrl
import {baseUrl} from "../../../services/adalConfig.js";

export default function TiposOracoes(props) {

	const [valuesDict, setValuesDict] = useState({nome_tipo_oracao: "", categoria_oracao: ""});
	const [rows, setRows] = useState([]);
	const [categorias, setCategorias] = useState([]);
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();

	const changeValues = (field, newValue) => {
		console.log("oi");
		var tempDict = valuesDict;

		tempDict = {...tempDict, [field]: newValue};
		console.log(tempDict);
		setValuesDict(tempDict);
	}

	async function handleEditRow(rowDict) {

		var tempTipo;
		if (rowDict.tipo == "Solenidade") {
			tempTipo = "ehSolenidade";
		} else if (rowDict.tipo == "Ocasião Especial") {
			tempTipo = "ehOcasiaoEspecial";
		}

		setValuesDict({...rowDict, tipo: tempTipo});
		props.setIsUpdateMode(true);
	}

	function cancelEditRow() {
		setValuesDict({nome_tipo_oracao: "", categoria_oracao: ""});
		props.setIsUpdateMode(false);
		atualizaDados();
	}

	async function submitForm() {

		if (props.isUpdateMode) {
			var postUrl = baseUrl + "/update-tipos-oracoes";
		} else {
			var postUrl = baseUrl + "/cadastrar-tipos-oracoes";
		}
		
		var payload = valuesDict;

		var emptyValues = [];
		var payloadKeys = Object.keys(payload);
		console.log("keys");
		console.log(payloadKeys);
		for (let i = 0; i < payloadKeys.length; i++) {
			if (payload[payloadKeys[i]] == "" || payload[payloadKeys[i]] == null) {
				if (payloadKeys[i] == "tipo") 
					continue;
				else 
					emptyValues.push(payloadKeys[i]);
			}
		}

		if (emptyValues.length > 0) {
			props.setAlertDict({open: true, texto: `Os seguintes campos estão vazios: ${emptyValues.join(", ")}`, severidade: "error"});
			return;
		}

		payload["url"] = payload["nome_tipo_oracao"].normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase().replaceAll("/", "").replaceAll("  ", " ").replaceAll("\n", "").replaceAll("\r", "-").replaceAll(" , ", "-").replaceAll(" ,", "-").replaceAll(", ", "-").replaceAll(" ", "-").replaceAll(",", "-").replaceAll(";", "-");

		payload["token"] = props.token;

		setLoading(true);

		axios.post(postUrl, payload)
			.then(resp => {
				console.log("Bach is cool");
				console.log(resp.data);
				setLoading(false);
				props.setAlertDict({open: true, texto: "Sucesso ao inserir novo tipo de oração!", severidade: "success"});
				cancelEditRow();
            })
            .catch((exp) => {
            	setLoading(false);
				props.setAlertDict({open: true, texto: "Erro ao inserir novo tipo de oração.", severidade: "error"});
            });
	}

	const columns = [
    	{
			field: 'nome_tipo_oracao',
		    headerName: 'Tipo Oração',
		    width: 140,
		    flex: 1
    	},
    	{
    		field: 'categoria_oracao',
		    headerName: 'Categoria Oração',
		    width: 140,
		    flex: 1
    	},  
		{
	        field: 'actions',
	        type: 'actions',
	        width: 80,
	        getActions: (params) => [
		        	          	<EditIcon
		        	          		onClick={() => handleEditRow(params.row)}
		        	          	/>,
		        	        ]
	    }
    ];

    function atualizaDados() {
    	var getUrl = baseUrl + "/get-tipos-oracoes";

        axios.get(getUrl)
            .then(resp => { 
            	var tempListCategorias = [];

            	for (var i = 0; i < resp.data.length; i++) {
            		if (tempListCategorias.includes(resp.data[i].categoria_oracao) == false) {
            			tempListCategorias.push(resp.data[i].categoria_oracao);
            		} 
            	}
            	setCategorias(tempListCategorias);
                setRows(resp.data); 
            });
    }

    useEffect(() => {

    	console.log("New value");
    	console.log(props.isEditMode);

    	if (props.isEditMode == true || rows.length == 0) {
    		atualizaDados();
    	}

    }, [props.isEditMode]);

    useEffect(() => {
    	if (props.isUpdateMode == false) {
    		cancelEditRow();
    	}
    }, [props.isUpdateMode]);

    if (props.isEditMode == true && props.isUpdateMode == false) {
    	return (
    		<DataGrid
    			autoHeight 
		        rows={rows}
		        columns={columns}
		        pageSizeOptions={[50]}
		        style={{width: "85%"}}
		        getRowId={(row) => row.id_tipo_oracao}
		    />
    	);
    } else
		return (
		<form noValidate onSubmit={() => console.log("oi turma")} id="formulario-login-cadastro-dados">
			<TextField
				inputProps={{style: {fontFamily: 'arial'}}} // font size of input text
  				InputLabelProps={{style: {fontFamily: 'arial'}}} // font size of input
	          	label="Nome Oração *"
	          	value={valuesDict["nome_tipo_oracao"]}
				onChange={(event) => {
				    changeValues("nome_tipo_oracao", event.target.value);
				}}
	        />
	       	<Autocomplete
	       		freeSolo
				id="fixed-tags-demo"
				value={valuesDict["categoria_oracao"]}
				inputValue={valuesDict["categoria_oracao"]}
		        onInputChange={(event, newInputValue) => {
		          	changeValues("categoria_oracao", newInputValue);
		        }}
				options={categorias}
				style={{ width: "100%" }}
				renderInput={(params) => (
					<TextField {...params} 
						label="Categoria Oração *" />
				)}
			/>
		    <LoadingButton loading={loading} onClick={() => submitForm()} style={{backgroundColor: "#bc1200"}} variant="contained">
		    	Salvar dados
		    </LoadingButton>
		</form>
	);
}