
import {useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";

import Divider from '@mui/material-next/Divider';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import axios from "axios";
import md5 from "md5";

// baseUrl
import {baseUrl} from "../../services/adalConfig.js";

import "./CadastroDados.css";

export default function Login(props) {

	const [email, setEmail] = useState("");
	const [senha, setSenha] = useState("");
	const [open, setOpen] = useState(false);

	const navigate = useNavigate();

	function loginUsuario() {

		var senhaEncriptada = md5(senha);

		var getUrl = baseUrl + "/logar?login=" + email + "&senhaEncriptada=" + senhaEncriptada;

        axios.get(getUrl)
            .then(resp => {

                if (resp.data == false) setOpen(true);
                else {
                	props.setToken(resp.data);
                	props.setTemAcesso(true);
                	navigate("/acesso-restrito/home");	
                }
            });

	}

	const handleClose = () => {
		setOpen(false);
	}

	const handleKeyDown = (event) => {
		console.log("oioi");
    	if (event.key === 'Enter') loginUsuario();
	}

	return (
		<>
			<Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
		        <Alert
		          onClose={handleClose}
		          severity="error"
		          variant="filled"
		          sx={{ width: '100%' }}
		        >
		         	Login ou senha inválidos
		        </Alert>
		     </Snackbar>
			<div style={{marginBottom: "100px", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", marginTop: "110px", overflow: "hidden"}}>
				<div class="musica-home-header">
	                <Divider sx={{
	                    "&::before, &::after": {
	                    borderColor: "#bc1200",
	                    },
	                }}>
	                    Login
	                </Divider>
	            </div>
	            <div id="formulario-login-cadastro-dados">
	            	<TextField
	            		inputProps={{style: {fontFamily: 'arial'}}} // font size of input text
  						InputLabelProps={{style: {fontFamily: 'arial'}}} // font size of input label
			          	id="outlined-password-input"
			          	label="Email"
			          	type="email"
			          	autoComplete="email"
			          	value={email}
						onChange={(event) => {
						    setEmail(event.target.value);
						}}
						onKeyDown={handleKeyDown}
			        />
	        		<TextField
	        			inputProps={{style: {fontFamily: 'arial'}}} // font size of input text
  						InputLabelProps={{style: {fontFamily: 'arial'}}} // font size of input label
			          	id="outlined-password-input"
			          	label="Password"
			          	type="password"
			          	autoComplete="current-password"
			          	value={senha}
			          	onChange={(event) => {
						    setSenha(event.target.value);
						}}
						onKeyDown={handleKeyDown}
			        />
			        <Button type="submit" onClick={() => loginUsuario()} style={{backgroundColor: "#bc1200"}} variant="contained">
				        Entrar
				     </Button>
	            </div>
			</div>
		</>
		);
}